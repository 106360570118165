<template>
    <div class="customer-detail-info">
        <el-form label-width="80px" size="mini">
            <el-form-item label="订单数">
                <el-tag type="info">{{customerVip.ORDER_COUNT}}</el-tag>
            </el-form-item>
            <el-form-item label="当前等级">
                <el-tag type="info">{{customerVip.CURRENT_LEVEL_NAME}}</el-tag>
            </el-form-item>
            <el-form-item label="*修改等级" class="red">
                <el-select v-model="customerVip.CURRENT_LEVEL" placeholder="请选择" @change="updateCustomerVipLevel">
                    <el-option
                            v-for="item in levels"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value">
                    </el-option>
                </el-select>
            </el-form-item>
            <el-form-item label="清除缓存">
                <el-button size="mini" type="danger" @click="delCustomerVipCache">清除会员缓存</el-button>
              <el-button size="mini" type="danger" @click="customerLevelUp">刷新用户等级</el-button>
            </el-form-item>
        </el-form>

      <el-tabs v-model="activeName" type="border-card"  @tab-click="initList">
        <el-tab-pane label="升级日志" name="0">
          <el-table border :data="customerVip.LEVEL_LOG" size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="fromLevel" label="源等级"></el-table-column>
            <el-table-column prop="toLevel" label="目标等级"></el-table-column>
            <el-table-column prop="createTime" label="发生时间"></el-table-column>
            <el-table-column prop="orderCount" label="订单数"></el-table-column>
            <el-table-column  label="升级方式">
              <template slot-scope="scope">
                {{scope.row.type==0?'手工升级':(scope.row.type==1?'订单升级':(scope.row.type==2?'付费升级':'未知'))}}
              </template>
            </el-table-column>
            <el-table-column prop="remark" label="备注"></el-table-column>
          </el-table>
        </el-tab-pane>
        <el-tab-pane label="购买日志" name="1"  >
          <el-table border :data="customerVip.LEVEL_BUY_LOG" size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column prop="orderMainNo" label="购买单号"></el-table-column>
            <el-table-column prop="createTime" label="购买时间"></el-table-column>
            <el-table-column prop="level" label="用户等级"></el-table-column>
            <el-table-column prop="beginTime" label="开始"></el-table-column>
            <el-table-column prop="endTime" label="结束"></el-table-column>
            <el-table-column prop="status" label="状态"></el-table-column>
            <el-table-column prop="freeStatus" label="赠品状态"></el-table-column>
          </el-table>
        </el-tab-pane>
      </el-tabs>



    </div>
</template>
<script>
    export default{
        data(){
            return {
                activeName:'0',
                customerId:this.$route.params.id,
                levels:[
                    {value:1,label:'1级会员'},
                    {value:2,label:'2级会员'},
                    {value:3,label:'3级会员'},
                    {value:4,label:'4级会员'},
                    {value:5,label:'5级会员'},
                    {value:6,label:'6级会员'},
                    {value:7,label:'7级会员'},
                    {value:20,label:'付费会员'},
                    {value:0,label:'普通用户'},
                ]
            }
        },
        props:['customerVip'],
        mounted(){
            console.log(this.customerId)
        },
        methods:{
            returnHistory(){
                window.history.go(-1)
            },
            customerLevelUp(){
              this.$apiGet('lechun-mall/vip/customerLevelUp',{customerId:this.customerId}).then(res=>{
                this.$confirm('此操作将重新计算会员等级, 是否继续?', '提示', {
                  confirmButtonText: '确定',
                  cancelButtonText: '取消',
                  type: 'warning'
                }).then(() => {
                  this.$message({
                    type: 'success',
                    message: '计算成功!'
                  });
                }).catch(() => {
                  this.$message({
                    type: 'info',
                    message: '失败'
                  });
                });

              })
            },
            delCustomerVipCache(){
                this.$apiGet('lechun-customers/customer/deleteUserVipInfoCache',{customerId:this.customerId}).then(res=>{
                    this.$confirm('此操作将清除会员缓存, 是否继续?', '提示', {
                        confirmButtonText: '确定',
                        cancelButtonText: '取消',
                        type: 'warning'
                    }).then(() => {
                        this.$message({
                            type: 'success',
                            message: '清除成功!'
                        });
                    }).catch(() => {
                        this.$message({
                            type: 'info',
                            message: '已取消清除'
                        });
                    });

                })
            },
            updateCustomerVipLevel(val){
                this.$apiGet('lechun-customers/customer/updateVipLevel',{customerId:this.customerId,level:val}).then(res=>{
                    this.$notify({
                        title: '成功',
                        message: '修改成功',
                        type: 'success'
                    });
                })
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .customer-detail-info{
        margin-top: 20px;
        .red .el-form-item__label{
            color: red;
        }
        .el-input{
            width: 100%;
            height: 32px;
        }
        .el-tag,.el-select{
            width: 100%;
            height: 32px;
        }
    }
</style>