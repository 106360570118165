<template>
    <div class="customer-detail-info">
        <el-table border :data="customerFollow" size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column label="来源" width="80">
                <template slot-scope="scope">
                    {{scope.row.source==2?'小程序':(scope.row.source==1?'公众号':'企业微信')}}
                </template>
            </el-table-column>
            <el-table-column prop="createTime" label="创建时间"></el-table-column>
            <el-table-column label="是否关注" width="100">
                <template slot-scope="scope">
                    {{scope.row.subscribe==1?'已关注':'未关注'}}
                </template>
            </el-table-column>
            <el-table-column prop="subscribeTime" label="关注时间"></el-table-column>
            <el-table-column prop="unSubscribeTime" label="取关时间"></el-table-column>
            <el-table-column prop="openId" label="OPENID"></el-table-column>

        </el-table>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                customerId:this.$route.params.id,
            }
        },
        props:['customerFollow'],
        mounted(){
            console.log(this.customerId)
        },
        methods:{

        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .customer-detail-info{
        margin-top: 20px;
        .red .el-form-item__label{
            color: red;
        }
        .el-input{
            width: 100%;
            height: 32px;
        }
        .el-tag,.el-select{
            width: 100%;
            height: 32px;
        }
    }
</style>