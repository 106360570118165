<template>
    <div>
        <el-form :inline="true" :model="formParam" class="demo-form-inline select-wrap" size="mini">
            <el-form-item label="主订单号">
                <el-input v-model="formParam.orderMainNo" placeholder="输入团购主订单号查询"></el-input>
            </el-form-item>
            <el-form-item>
                <el-button type="primary" @click="initList">查询</el-button>
            </el-form-item>
        </el-form>
        <tablePagination :total="total" :pageSize="formParam.pageSize" @handlePage="handlePage">
            <el-table :data="tableList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column prop="activeNo" label="活动号"></el-table-column>
                <el-table-column prop="activeName" label="活动名称"></el-table-column>
                <el-table-column prop="nickName" label="团主昵称">
                    <template slot-scope="scope">
                        <a :href="getCustomerLink(scope.row.customerId)" alt="点击查看用户"
                           target="_blank">{{scope.row.nickName}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="headImageUrl" label="头像" >
                    <template slot-scope="scope">
                        <el-avatar size="small" :src="scope.row.headImageUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="inviteCount" label="参团数" width="120"></el-table-column>
                <el-table-column prop="createTime" label="开团时间"></el-table-column>
                <el-table-column prop="endTime" label="结束时间"></el-table-column>
                <el-table-column prop="prizeStatusName" label="团状态" width="120" sortable>
                </el-table-column>
                <el-table-column prop="prizeTime" label="成团时间" sortable>
                    <template slot-scope="scope">
                        {{scope.row.isPrize == 1 ? scope.row.prizeTime : '-'}}
                    </template>
                </el-table-column>
                <el-table-column prop="orderMainNo" label="团主订单号" sortable>
                    <template slot-scope="scope">
                        <a :href="getOrderLink(scope.row.orderMainNo)" alt="点击查看用户订单"
                           target="_blank">{{scope.row.orderMainNo}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="joinNum" label="成团数" width="120" sortable></el-table-column>
                <el-table-column label="操作" fixed="right" width="120">
                    <template slot-scope="scope">
                        <el-button type="success" v-show="scope.row.inviteCount>=2" size="mini"
                                   @click="getInviteDetail(scope.row)">参团明细
                        </el-button>
                    </template>
                </el-table-column>
            </el-table>
        </tablePagination>
        <el-dialog title="参团订单" :visible.sync="showDialog">
            <el-table :data="detailList" border size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <!--<el-table-column prop="customerId" label="团员customerid" sortable>-->
                    <!--<template slot-scope="scope">-->
                        <!--<a :href="getCustomerLink(scope.row.customerId)" alt="点击查看用户订单"-->
                           <!--target="_blank">{{scope.row.nickName}}</a>-->
                    <!--</template>-->
                <!--</el-table-column>-->
                <el-table-column prop="nickName" label="团员昵称" sortable>
                    <template slot-scope="scope">
                        <a :href="getCustomerLink(scope.row.customerId)" alt="点击查看用户"
                           target="_blank">{{scope.row.nickName}}</a>
                    </template>
                </el-table-column>
                <el-table-column prop="headImageUrl" label="头像">
                    <template slot-scope="scope">
                        <el-avatar size="small" :src="scope.row.headImageUrl"></el-avatar>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime" label="参团时间"></el-table-column>
                <el-table-column prop="statusName" label="团状态" sortable>
                </el-table-column>
                <el-table-column prop="orderMainNo" label="团员订单号" sortable>
                    <template slot-scope="scope">
                        <a :href="getOrderLink(scope.row.orderMainNo)" alt="点击查看用户订单"
                           target="_blank">{{scope.row.orderMainNo}}</a>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>

    </div>

</template>

<script>
    import tablePagination from '@c/tablePagination'
    export default {
        data() {
            return {
                formParam: {
                    pageSize: 15,
                    currentPage: 1,
                    orderMainNo: '',
                    customerId: this.$route.params.id
                },
                tableList: [],
                detailList: [],
                total: 0,
                showDialog: false,
                queryFilter: {}
            }
        },
        components: {
            tablePagination
        },
        mounted(){
            this.initList();
        },
        methods: {
            initList() {
                this.$apiGet('lechun-market/groupon/getGrouponList', this.formParam).then(res => {
                    console.log(res)
                    this.total = res.total;
                    this.tableList = res.list
                })
            },
            getInviteDetail(row){
                this.detailList = row.grouponDetailOrderVoList;
                this.showDialog = true;
            },
            handlePage(page){
                this.formParam.currentPage = page;
                this.initList()
            },
            getOrderLink(orderMainNo){
                return `${window.location.origin}/orderList?externalMainOrderNo=${orderMainNo}`
            },
            getCustomerLink(customerId){
                let returnUrl = ''
                returnUrl = `${window.location.origin}/userList/detail/${customerId}`
                return returnUrl
            },
            convertStatus(row, column, cellValue) {
                if (row.status == 1) {
                    return "启用";
                } else {
                    return "禁用";
                }
            }
        }
    }
</script>


<style scoped>

</style>