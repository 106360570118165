<template>
        <defaultSec :title="'< 返回'" :returnState="true">

            <el-tabs v-model="activeName" type="border-card"  @tab-click="initList">
                <el-tab-pane label="客户信息" name="0">
                    <customerInfo :customerInfo="customerInfo"></customerInfo>
                </el-tab-pane>
                <el-tab-pane label="会员信息" name="1"  >
                    <customerVip :customerVip="customerVip"></customerVip>
                </el-tab-pane>
                <el-tab-pane label="关注记录" name="2" >
                    <customerFollow :customerFollow="customerFollow"></customerFollow>
                </el-tab-pane>
                <el-tab-pane label="客户地址" name="3" >
                    <customerAddress></customerAddress>
                </el-tab-pane>
                <el-tab-pane label="客户余额" name="4" >
                    <customerBalance></customerBalance>
                </el-tab-pane>
                <el-tab-pane label="客户优惠券" name="5">
                    <customerCoupon></customerCoupon>
                </el-tab-pane>
                <el-tab-pane label="预付卡" name="6" >
                    <customerCard></customerCard>
                </el-tab-pane>
                <el-tab-pane label="参与活动" name="7" >
                    <activeRecord></activeRecord>
                </el-tab-pane>

                <el-tab-pane label="订单" name="8">
                    <orderListInfo :formParam="orderFormParam"></orderListInfo>
                </el-tab-pane>
                <el-tab-pane label="赠品池" name="9">
                  <customerFree ></customerFree>
                </el-tab-pane>

            </el-tabs>
        </defaultSec>
</template>

<script>
    import defaultSec from '@c/defaultSection'
    import customerInfo from '@/components/userSystem/customerInfo'
    import customerVip from '@/components/userSystem/customerVip'
    import customerFollow from '@/components/userSystem/customerFollow'
    import customerAddress from '@/components/userSystem/customerAddress'
    import customerCoupon from '@/components/userSystem/customerCoupon'
    import customerBalance from '@/components/userSystem/customerBalance'
    import customerCard from '@/components/userSystem/customerCard'
    import orderListInfo from "@c/orderSystem/orderListInfo"
    import activeRecord from '@/components/active/activeList'
    import customerFree from '@/components/userSystem/customerFree'
    export default {
        data() {
            return {
                customerId:this.$route.params.id,
                customerInfo:{},
                customerVip:{},
                customerFollow:[],
                activeName:'0',
                orderFormParam:{
                    pageSize:15,
                    currentPage:1,
                    externalOrderNo:'',
                    customerId:this.customerId,
                }
            }
        },
        components:{
            defaultSec,customerInfo,customerVip,customerFollow,customerAddress,
            customerCoupon,customerBalance,customerCard,activeRecord,orderListInfo,customerFree
        },
        mounted(){
            this.initList()
        },
        methods: {
            initList() {
                if(this.activeName==0){
                    this.$apiGet('lechun-customers/customer/getCustomerInfo',{customerId:this.customerId}).then(res => {
                        this.customerInfo = res.custmerInfo
                        this.customerInfo.level=res.centerInfo.CURRENT_LEVEL
                        this.customerInfo.levelName=res.centerInfo.CURRENT_LEVEL_NAME
                        this.customerVip = res.centerInfo
                        this.customerFollow = res.openIdList
                    })
                }

            }
        }
    }
</script>


<style scoped>

</style>