<template>
    <div class="customer-detail-info">
        <tablePagination :total="totalRecord" :pageSize="formInline.pageSize"  @handlePage="handleCurrentChange">
            <el-table border :data="cardList" size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column type="expand" label="展开"  width="60">
                    <template slot-scope="scope">
                        <el-form label-position="left"  class="demo-table-expand">
                            <el-form-item label="卡id">
                                <span>{{ scope.row.cardId }}</span>
                            </el-form-item>
                            <el-form-item label="批次">
                                <span>{{ scope.row.batchName }}</span>
                            </el-form-item>
                            <el-form-item label="卡名称">
                                <span>{{ scope.row.title }}</span>
                            </el-form-item>
                            <el-form-item label="渠道">
                                <span>{{ scope.row.orderSourceName }}</span>
                            </el-form-item>
                            <el-form-item label="卡订单号">
                                <span>{{ scope.row.orderMainNo }}</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="relateCardNo" label="卡号" width="180"></el-table-column>
                <el-table-column label="卡状态" prop="statusName" width="80"></el-table-column>
                <el-table-column prop="createTime" label="兑换/激活日期"></el-table-column>
                <el-table-column prop="endTime" label="过期日期" width="180"></el-table-column>
                <el-table-column prop="totalCount" label="总数" width="90">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.cardType==3" type="info" effect="plain">{{scope.row.saleValue}}点</el-tag>
                        <el-tag size="mini" v-if="scope.row.cardType==4||scope.row.cardType==5" type="danger" effect="plain">{{scope.row.saleValue}}元</el-tag>
                        <el-tag size="mini" v-if="scope.row.cardType==1||scope.row.cardType==2" type="success" effect="plain">{{scope.row.totalCount}}次</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="noDeliveryNum" label="待发货" width="90">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.cardType==3" type="info" effect="plain">{{scope.row.noDeliveryAmount}}点</el-tag>
                        <el-tag size="mini" v-if="scope.row.cardType==4||scope.row.cardType==5" type="danger" effect="plain">{{scope.row.noDeliveryAmount}}元</el-tag>
                        <el-tag size="mini" v-if="scope.row.cardType==1||scope.row.cardType==2" type="success" effect="plain">{{scope.row.noDeliveryNum}}次</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="remainCount" label="未使用" width="90">
                    <template slot-scope="scope">
                        <el-tag size="mini" v-if="scope.row.cardType==3" type="info" effect="plain">{{scope.row.remainAmount}}点</el-tag>
                        <el-tag size="mini" v-if="scope.row.cardType==4||scope.row.cardType==5" type="danger" effect="plain">{{scope.row.remainAmount}}元</el-tag>
                        <el-tag size="mini" v-if="scope.row.cardType==1||scope.row.cardType==2" type="success" effect="plain">{{scope.row.remainCount}}次</el-tag>
                    </template>
                </el-table-column>
                <el-table-column prop="cardTypeName" label="卡类型" width="90"></el-table-column>
<!--                <el-table-column prop="unit" label="单位" width="60"></el-table-column>-->
                <el-table-column label="操作" fixed="right" width="170">
                    <template slot-scope="scope">
                        <el-button-group>
                            <el-button plain v-if="scope.row.status==2||scope.row.status==5||scope.row.status==6" type="primary" size="mini" @click="handleDetail(scope.row)">查看</el-button>
                            <el-button v-if="(scope.row.status==2||scope.row.status==4||scope.row.status==5)&&scope.row.endTime!=null&&scope.row.remainCount>0&&(new Date()>new Date(scope.row.endTime)) " type="warning" size="mini" @click="handleExpire(scope.row)">延期</el-button>
                            <el-button plain v-if="scope.row.status==2||scope.row.status==1" type="danger" size="mini" @click="disabledCard(scope.row.cardId)">禁用</el-button>
                        </el-button-group>
                    </template>
                </el-table-column>
            </el-table>
            <el-dialog
                    title="预付卡详情"
                    :visible.sync="drawerShow"
                     fullscreen>
            <el-table border :data="cardDetailList" size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column type="expand" label="展开看详情"  width="100">
                    <template slot-scope="scope">
                        <el-form label-position="left"   class="demo-table-expand">
                            <el-form-item label="卡Id">
                                <span>{{ scope.row.cardId }}</span>
                            </el-form-item>
                            <!--<el-form-item label="省">-->
                                <!--<span>{{ scope.row.provinceName }}</span>-->
                            <!--</el-form-item>-->
                            <!--<el-form-item label="市">-->
                                <!--<span>{{ scope.row.cityName }}</span>-->
                            <!--</el-form-item>-->

                            <el-form-item label="地址">
                                <span>{{ scope.row.address }}</span>
                            </el-form-item>
                            <el-form-item label="收件人">
                                <span>{{ scope.row.consigneeName }}</span>
                            </el-form-item>
                            <el-form-item label="电话">
                                <span>{{ scope.row.consigneePhone }}</span>
                            </el-form-item>
                            <el-form-item label="商品">
                                <span>{{ scope.row.productName }}</span>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
                <el-table-column prop="orderMainNo" label="订单号" width="200">
                    <template slot-scope="scope">
                        <div>
                            主单号: {{scope.row.orderMainNo}}<i style="color: #55a532;cursor: pointer" @click="copyOrderNo(scope.row.externalOrderNo)" class="el-icon-document-copy"></i>
                        </div>
                        <div>
                            <el-button type="success" size="mini" @click="goOrderDetail(scope.row.orderMainNo)" stylet="text-align:center;">查看订单</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="cardOrderMainNo" label="卡订单号" width="200">
                    <template slot-scope="scope">
                        <div>
                            主单号: {{scope.row.orderMainNo}}<i style="color: #55a532;cursor: pointer" @click="copyOrderNo(scope.row.externalOrderNo)" class="el-icon-document-copy"></i>
                        </div>
                        <div>
                            <el-button type="success" size="mini" @click="goOrderDetail(scope.row.orderMainNo)" stylet="text-align:center;">查看订单</el-button>
                        </div>
                    </template>
                </el-table-column>
                <el-table-column prop="createTime"   label="创建时间" >
                    <template slot-scope="scope">
                        <span>{{formatDate(scope.row.createTime)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="pickupDate"  label="发货日期" width="90">
                    <template slot-scope="scope">
                        <span>{{formatDate(scope.row.pickupDate)}}</span>
                    </template>
                </el-table-column>
                <el-table-column prop="statusName"   label="状态" width="80" ></el-table-column>
                <el-table-column prop="quantity" label="数量" width="60">
                    <template slot-scope="scope">
                        <span >
                            {{scope.row.quantity}}{{scope.row.box}}
                        </span>
                    </template>
                </el-table-column>
                <el-table-column prop="orderSourceName" label="来源" ></el-table-column>
                <el-table-column  label="售价/点数" fixed="right">
                    <template slot-scope="scope">
                        <span >
                            {{scope.row.pricePoint}}
                        </span>
                    </template>
                </el-table-column>
                <!--<el-table-column prop="perPrice" label="价格" fixed="right"></el-table-column>-->
                <el-table-column fixed="right">
                  <template slot-scope="scope">
                      <el-button-group>
                          <el-tooltip class="item" effect="dark" content="取消订单" placement="top-end">
                            <el-button type="primary" size="mini" v-if="(scope.row.status==4||scope.row.status==2)&&scope.row.orderMainNo.length>20" @click="cancelRealOrder(scope.row)">取消订单</el-button>
                          </el-tooltip>
                          <el-button type="success"  size="mini" v-if="scope.row.status==2&&scope.row.orderMainNo=='点卡订单待生成'"
                                     @click="toEditAddress(scope.row)">改地址
                            </el-button>
                          <el-button type="primary"  size="mini" v-if="scope.row.status==2&&scope.row.orderMainNo=='点卡订单待生成'"
                                     @click="toEditDeliveryTime(scope.row)">改日期
                            </el-button>
                          <el-button type="warning"  size="mini" v-if="scope.row.status==2&&scope.row.orderMainNo=='点卡订单待生成'"
                                                    @click="toEditProduct(scope.row)">改商品
                            </el-button>
                          <el-tooltip class="item" effect="dark" content="取消计划" placement="top-end">
                              <el-button type="danger"  size="mini" v-if="scope.row.status==2&&(scope.row.orderMainNo==''||scope.row.orderMainNo==null)"
                                         @click="cancelPlan(scope.row.detailedId)">取消计划
                                </el-button>
                          </el-tooltip>
                      </el-button-group>
                  </template>
                </el-table-column>
            </el-table>
            </el-dialog>
        </tablePagination>
        <el-dialog title="设置过期时间" :visible.sync="showDialog">
            <el-form :model="card" label-width="120px">
                <el-form-item>
                    <el-date-picker
                            v-model="card.endTime"
                            type="datetime"
                            value-format="yyyy-MM-dd HH:mm:ss"
                            placeholder="设置卡过期时间">
                    </el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="setCardExpire()">保 存</el-button>
                    <el-button @click="showDialog = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog
                title="请选择地址"
                :visible.sync="showAddress">
            <el-table border :data="addressList" highlight-current-row size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="收件人" width="100" prop="consigneeName"></el-table-column>
                <el-table-column prop="consigneePhone" label="电话" width="110"></el-table-column>
                <el-table-column label="省" prop="provinceName" width="100"></el-table-column>
                <el-table-column prop="cityName" label="市" width="100"></el-table-column>
                <el-table-column prop="areaName" label="区" width="100"></el-table-column>
                <el-table-column prop="address" label="详细地址"></el-table-column>
                <el-table-column label="类型" width="80">
                    <template slot-scope="scope">
                        <el-tag type="info">{{addressTypeArr[scope.row.addressType-1].name}}</el-tag>
                    </template>
                </el-table-column>
                <el-table-column label="操作" width="120" fixed="right">
                    <template slot-scope="scope">
                        <el-button type="success" size="mini" @click="select(scope.row)">选择</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </el-dialog>
        <el-dialog
                title="修改提货日期信息"
                :visible.sync="showDeliverDate"
               >
            <el-form ref="form" :model="plandDetail" label-width="80px" class="draw-box">
                <el-form-item label="提货日期">
                    <el-date-picker v-model="plandDetail.pickupDate" type="date" placeholder="选择日期"
                                    value-format="yyyy-MM-dd"></el-date-picker>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveDeliveryTime">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog
                title="商品列表"
                :visible.sync="showProduct">
            <div>
                <el-row :gutter="20">
                    <span style="color: red"><h3>加减商品后 点击保存按钮后生效</h3></span>
                </el-row>
                <el-row :gutter="20">
                    <el-table border :data="productList" size="mini" >
                        <el-table-column type="index" label="序号" width="50"></el-table-column>
                        <el-table-column label="商品名称" width="100" prop="productName"></el-table-column>
                        <el-table-column label="售价" prop="saleValue" width="100"></el-table-column>
                        <el-table-column label="原价" prop="cardOriginPrice" ></el-table-column>
                        <el-table-column label="数量" prop="quantity" ></el-table-column>
                        <el-table-column label="合计"  >
                            <template slot-scope="scope">
                                <span>{{scope.row.quantity==0?0:(scope.row.saleValue*scope.row.quantity)}}</span>
                            </template>
                        </el-table-column>
                        <el-table-column label="操作" width="280" fixed="right">
                            <template slot-scope="scope">
                                <el-button-group>
                                    <el-tooltip class="item" effect="dark" content="点击减商品" placement="top-start">
                                        <el-button type="primary" v-if="scope.row.quantity>0" size="mini" @click="removeProduct(scope.row)" icon="el-icon-zoom-out" ></el-button>
                                    </el-tooltip>
                                    <el-tooltip class="item" effect="dark" content="点击加商品" placement="top-end">
                                        <el-button type="success" size="mini" @click="addProduct(scope.row)"  icon="el-icon-zoom-in"></el-button>
                                    </el-tooltip>
                                </el-button-group>
                            </template>
                        </el-table-column>
                    </el-table>
                </el-row>
                <el-row :gutter="20">
                    <el-form :inline="true"  class="demo-form-inline select-wrap" >
                        <el-form-item>
                            <el-button type="primary" size="mini" v-if="productList.length>0" @click="savePointPlan">保存计划</el-button>
                        </el-form-item>
                    </el-form>
                </el-row>
            </div>
        </el-dialog>
    </div>
</template>
<script>
    import tablePagination from '@c/tablePagination'

    export default{
        data(){
            return {
                loading: true,
                cardList:[],
                addressList:[],
                addressTypeArr:[],
                cardDetailList:[],
                drawerShow:false,
                showDialog:false,
                showAddress:false,
                showProduct:false,
                cardInfo:{},
                showDeliverDate:false,
                plandDetail:{},
                limitMinCount:0,
                saleValue:0,
                productList:[],
                product:{
                    platformId:4,
                    customerId:'',
                    salesType:4,
                    salesId:'',
                    activeNo:'',
                    quantity:1,
                    isQuick:false,
                    shoppingType:''
                },
                card:{
                    endTime:'',
                    cardId:''
                },
                formInline: {
                    customerId:this.$route.params.id,
                    platformId: 1,
                    currentPage: 1,
                    pageSize: 10
                },
                totalRecord: 0
            }
        },
        components:{
            tablePagination
        },
        mounted(){
            this.initList()
        },
        methods:{
            initList() {
                this.$apiGet('lechun-customers/customerBalance/getPrepayCardVoList',this.formInline).then(res => {
                    console.log(res)
                    this.totalRecord = res.total;
                    this.cardList = res.list;
                })
            },
            handleDetail(row) {
                this.drawerShow = true;
                this.card.cardId=row.cardId;
                this.card.endTime=row.endTime;
                this.getDetail(row.cardId);
            },
            getDetail(cardId){
                this.drawerShow = true;
                this.loading=true;
                this.$apiGet('lechun-customers/customerBalance/getCardPlanDetail',{cardId: cardId}).then(res => {
                    console.log(res)
                    //this.totalRecord = res.total;
                    this.loading=false;
                    this.cardDetailList = res.planDetailList;
                    this.cardInfo=res;
                })
            },
            copyOrderNo(text){
                var input = document.createElement("textarea"); // 创建input对象
                input.value = text; // 设置复制内容
                document.body.appendChild(input); // 添加临时实例
                input.select(); // 选择实例内容
                document.execCommand("Copy"); // 执行复制
                document.body.removeChild(input); // 删除临时实例
                this.$message({
                    type: 'success',
                    message: '已复制到剪贴板'
                });
            },
            handleExpire(row){
                this.showDialog=true;
                this.card.cardId=row.cardId;
                this.card.endTime=row.endTime;
            },
            setCardExpire(){
                this.$confirm('确认是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/prepay/setCardExpireTime',this.card).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '设置成功',
                            type: 'success'
                        });
                        this.initList();
                    })
                }).catch(() => {
                });
                this.showDialog=false;
            },
            disabledCard(id){
                this.$confirm('此操作将禁用该预付卡, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-customers/customerBalance/disabledCard',{cardId:id}).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '禁用成功',
                            type: 'success'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            cancelPlan(detailedId){
                this.$confirm('此操作将取消计划, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-customers/customerBalance/cancelPlan',{detailedId:detailedId}).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '取消成功',
                            type: 'success'
                        });
                        this.getDetail(this.card.cardId);
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            cancelRealOrder(row){
                this.$confirm('此操作将取消该订单并返还次数/点数到卡上, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-csms/refund/refundPrepayCard',{orderNo:row.orderMainNo}).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '取消成功',
                            type: 'success'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            handleCurrentChange(val) {
                this.formInline.pageCount = val;
                this.initList();
            },
            unableCard(row){
                //禁用卡
            },
            getLink(orderMainNo){
                let returnUrl = ''
                returnUrl = `${window.location.origin}/#/orderList?externalMainOrderNo=${orderMainNo}`
                return returnUrl
            },
            goOrderDetail(orderMainNo){
                this.$router.push({path: '/orderList', name: 'orderList', params: {
                    orderMainNo:orderMainNo,
                    buyerId: this.$route.params.id,
                }})
            },
            toEditAddress(row){
                this.showAddress=true;
                this.loading=true;
                this.plandDetail=row;
                this.$apiGet('lechun-customers/customer/getCustomerAddressInfo',{customerId:this.formInline.customerId}).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.addressList = res.addressList
                    this.addressTypeArr = res.addressType
                })
            },
            saveAddress(){
                this.showAddress=false;
            },
            toEditDeliveryTime(row){
                this.showDeliverDate=true;
                row.pickupDate=this.formatDate(row.pickupDate)
                this.plandDetail=row;
            },
            toEditProduct(row){
                this.showProduct=true;
                this.plandDetail=row;
                this.getProductList();
            },
            getProductList(){
                this.$apiGet('lechun-customers/prepayCard/getPrepayCardItemList',{detailedId:this.plandDetail.detailedId,customerId:this.formInline.customerId,bindCode:this.cardInfo.bindCode}).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.productList=res.item;
                    this.saleValue=res.saleValue
                    this.limitMinCount=res.limitMinCount
                })
            },
            addProduct(row){
                console.log(row)
                this.loading=true;
                this.product={
                    platformId:4,
                        customerId:this.formInline.customerId,
                        salesType:4,
                        salesId:row.productId,
                        activeNo:'',
                        quantity:1,
                        isQuick:false,
                        shoppingType:this.cardInfo.bindCode
                }
                this.$apiGet('lechun-mall/shoppingCarApi/addCart',this.product).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.getProductList();
                })
            },
            removeProduct(row){
                console.log('减商品....')
                this.loading=true;
                this.$apiGet('lechun-mall/shoppingCarApi/addCart',{
                    platformId:4,
                    customerId:this.formInline.customerId,
                    salesType:4,
                    salesId:row.productId,
                    activeNo:'',
                    quantity:-1,
                    isQuick:false,
                    shoppingType:this.cardInfo.bindCode
                }).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.getProductList();
                })
            },
            savePointPlan(){
                let usedPoint=0;
                this.productList.filter(function (item) {
                    if(item.quantity>0){
                        usedPoint=usedPoint+item.quantity*item.saleValue;
                    }
                });
                if(usedPoint>this.saleValue){
                    this.$message({
                        type: 'error',
                        message: '余额点数'+this.saleValue+',选择商品点数:'+usedPoint+',余额不足.'
                    });
                    return;
                }
                if(usedPoint<this.limitMinCount){
                    this.$message({
                        type: 'error',
                        message: '选择商品点数:'+usedPoint+',最低起送点数:'+this.limitMinCount+',请再加点商品'
                    });
                    return;
                }
                this.loading=true;
                this.$apiGet('lechun-customers/prepayCard/savePointCardOrderPlan',{detailedId: this.plandDetail.detailedId,pickDate:this.plandDetail.pickupDate}).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.showProduct=false;
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.getDetail(this.card.cardId);
                })
            },
            saveDeliveryTime(){
                this.loading=true;
                this.$apiGet('lechun-customers/prepayCard/saveCardPlanPickDate',{detailedId: this.plandDetail.detailedId,pickDate:this.plandDetail.pickupDate}).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.showDeliverDate=false;
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.getDetail(this.card.cardId);
                })
            },
            select(row){
                //选择地址保存
                this.loading=true;
                this.$apiGet('lechun-customers/prepayCard/saveCardPlanAddress',{detailedId: this.plandDetail.detailedId,addrId:row.addrId}).then(res => {
                    console.log(res)
                    this.loading=false;
                    this.showAddress=false;
                    this.$message({
                        type: 'success',
                        message: '保存成功!'
                    });
                    this.getDetail(this.card.cardId);
                })
            },
            handleClose(){

            },
            formatDate(datetime) {
                if(datetime==null){
                    return datetime;
                }
                var date = new Date(datetime);
                var year = date.getFullYear(),
                    month = ("0" + (date.getMonth() + 1)).slice(-2),
                    sdate = ("0" + date.getDate()).slice(-2),
                    hour = ("0" + date.getHours()).slice(-2),
                    minute = ("0" + date.getMinutes()).slice(-2),
                    second = ("0" + date.getSeconds()).slice(-2);
                // 拼接
                var result = year + "-" + month + "-" + sdate + " " + hour + ":" + minute + ":" + second;
                // 返回
                return result;
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .demo-table-expand .el-form-item {
        margin-bottom: 0px;
    }
</style>