<template>
    <div class="customer-detail-info">
        <el-form :inline="true" :model="formInline" class="demo-form-inline select-wrap">

            <el-form-item>
                <el-button type="success" v-show="false" @click="addBatchFree()">添加赠品</el-button>
            </el-form-item>
            <el-form-item>
                <el-button type="success" @click="recordBatchFree()">授权赠品</el-button>
            </el-form-item>
        </el-form>
        <el-table border :data="customerFreeList" size="mini">

            <el-table-column type="index" label="序号" width="50"></el-table-column>


            <el-table-column label="卡批次/会员等级" prop="cardBatchName" width="120">

            </el-table-column>
            <el-table-column label="卡ID" prop="cardId" width="120">

            </el-table-column>
            <el-table-column label="赠品名称" prop="productName" width="80">

            </el-table-column>
            <el-table-column label="来源" prop="sourceName" width="70"></el-table-column>
            <el-table-column prop="quantity" label="总数量">
            </el-table-column>
            <el-table-column prop="enableQuantity" label="可用数量"></el-table-column>

            <el-table-column prop="status" label="状态">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.status==0" type="info" effect="plain">无效</el-tag>
                    <el-tag size="mini" v-if="scope.row.status==1" type="success" effect="plain">可用</el-tag>
                    <el-tag size="mini" v-if="scope.row.status==2" type="warning" effect="plain">已使用</el-tag>
                    <el-tag size="mini" v-if="scope.row.status==3" type="warning" effect="plain">不在有效期</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="enableDate" label="有效期"></el-table-column>
            <el-table-column prop="createTime" label="创建时间" width="180"></el-table-column>
            <el-table-column prop="freeMethod" label="赠送方式">
                <template slot-scope="scope">
                    <el-tag size="mini" v-if="scope.row.freeMethod==1" type="success" effect="plain">随卡单</el-tag>
                    <el-tag size="mini" v-if="scope.row.freeMethod==2" type="success" effect="plain">赠品池</el-tag>
                </template>
            </el-table-column>

            <el-table-column label="操作" fixed="right" width="170">
                <template slot-scope="scope">
                    <el-button-group>
                        <el-button plain v-if="scope.row.status==1||scope.row.status==3" type="danger" size="mini"
                                   @click="disabledFreePoolProduct(scope.row.id)">禁用
                        </el-button>
                        <el-button plain v-if="scope.row.status!=2" type="danger" size="mini"
                                   @click="cleanFreePoolProduct(scope.row.id)">重置
                        </el-button>
                    </el-button-group>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog title="直接为用户添加到赠品池" :visible.sync="showDialog">
            <el-form :model="form" label-width="120px">
                <el-form-item label="奶卡批次">
                    <el-select v-model="form.cardId" placeholder="选择奶卡批次" @change="selectBatch">
                        <el-option v-for="item in cardBatchList"
                                   :key="item.cardId"
                                   :label="item.batchName"
                                   :value="item.cardId">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="奶卡赠品">
                    <el-select v-model="form.id" placeholder="选择赠品">
                        <el-option v-for="item in cardBatchFreeList"
                                   :key="item.id"
                                   :label="item.productName"
                                   :value="item.id">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-input-number v-model="form.quantity" :min="1" max="form.quantity"
                                     placeholder=""></el-input-number>
                </el-form-item>
                <el-form-item>
                    <el-input v-model="form.orderMainNo" placeholder="输入用户下单订单号"></el-input>
                    输入用户下单订单号可以记录企微业绩,如果是微信订单号由系统自动记录业绩,无需输入

                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveCardBatchFree()">保 存</el-button>
                    <el-button @click="showDialog = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
        <el-dialog title="授权赠品，用户需自行添加到赠品池" :visible.sync="showDialogRecord">
            <el-form :model="form" label-width="120px">

                <el-form-item>
                    <el-input v-model="form.orderMainNo" placeholder="输入用户下单订单号"></el-input>
                    输入用户下单订单号可以记录企微业绩和授权用户赠品,用户激活时可以自行添加到赠品池

                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="saveRecordCardBatchFree()">保 存</el-button>
                    <el-button @click="showDialogRecord = false">取 消</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>

    </div>
</template>
<script>

    export default{
        data(){
            return {
                loading: true,
                customerFreeList: [],
                showDialog: false,
                showDialogRecord: false,
                cardBatchList: [],
                cardBatchFreeList: [],
                formInline: {
                    customerId: this.$route.params.id,
                    platformId: 1,
                    currentPage: 1,
                    pageSize: 10
                },
                form: {
                    cardId: null,
                    source: 1,
                    id: "",
                    orderMainNo: '',
                },

            }
        },
        components: {},
        mounted(){
            this.initList()
        },
        methods: {
            initList() {
                this.$apiGet('lechun-cms/customerFree/getCustomerFreePool', this.formInline).then(res => {
                    this.customerFreeList = res;
                });
                this.$apiGet('lechun-cms/prepay/getCardBatchHaveFree', {"customerId": this.formInline.customerId}).then(res => {
                    this.cardBatchList = res;
                })

            },
            selectBatch(){
                this.$apiGet('lechun-cms/prepay/getCardFreeProduct', {"cardId": this.form.cardId}).then(res => {
                    this.cardBatchFreeList = res;
                })
            },
            addBatchFree(){
//                this.$message({
//                    showClose: true,
//                    message: '暂时不支持客服添加赠品，请授权用户自行添加赠品',
//                    type: 'error'
//                });
//                return;
                ///暂时不支持客服赠送
                this.form = {
                    batchId: null,
                    source: 1,
                    id: "",
                    quantity: 1
                }
                this.showDialog = true;

            },
            recordBatchFree(){
                this.form = {
                    batchId: null,
                    source: 1,
                    id: "",
                    quantity: 1,
                    orderMainNo: ""
                }
                this.showDialogRecord = true;

            },
            saveCardBatchFree(){
                var saveObject = null;
                if (this.form.batchId == null) {
                    this.$message({
                        showClose: true,
                        message: '请选择奶卡批次',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.id == '') {
                    this.$message({
                        showClose: true,
                        message: '请选择奶卡赠品',
                        type: 'error'
                    });
                    return;
                }
                if (this.form.quantity == '') {
                    this.$message({
                        showClose: true,
                        message: '请选择数量',
                        type: 'error'
                    });
                    return;
                }
                this.cardBatchFreeList.find(o => {
                    if (o.id == this.form.id) {
                        saveObject = {
                            customerId: this.$route.params.id,
                            productId: o.productId,
                            productName: o.productName,
                            productType: o.productType,
                            freeId: o.freeId,
                            cardBatchId: o.batchId,
                            cardId: o.cardId,
                            source: o.source,
                            quantity: this.form.quantity,
                            freeMethod: o.freeMethod,
                            orderMainNo: this.form.orderMainNo,
                        }
                        this.$apiPost('lechun-cms/customerFree/addFreeProductToPool', saveObject).then(res => {
                            this.$notify({
                                title: '成功',
                                message: '添加成功',
                                type: 'success'
                            });
                            this.showDialog = false;
                            this.initList()
                        });
                    }
                });
            },
            saveRecordCardBatchFree(){

                if (this.form.orderMainNo == "") {
                    this.$message({
                        showClose: true,
                        message: '请请输入订单号',
                        type: 'error'
                    });
                    return;
                }

                this.$apiGet('lechun-cms/customerFree/recordFreeAndDistribution', {
                    "orderMainNo": this.form.orderMainNo,
                    "customerId": this.formInline.customerId
                }).then(res => {
                    this.$notify({
                        title: '成功',
                        message: '添加成功',
                        type: 'success'
                    });
                    this.showDialogRecord = false;
                    this.initList()
                });


            },
            disabledFreePoolProduct(id){
                this.$confirm('此操作将禁用该赠品, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/customerFree/disabledFreePoolProduct', {id: id}).then(res => {
                        this.$notify({
                            title: '成功',
                            message: '禁用成功',
                            type: 'success'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            cleanFreePoolProduct(id){
                this.$confirm('注意：此操作将重置该赠品,该卡下的所有赠品重置后，用户可重新选择赠品， 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-cms/customerFree/cleanFreePoolProduct', {id: id}).then(res => {
                        this.$notify({
                            title: '成功',
                            message: '重置成功',
                            type: 'success'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            }
        },
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .demo-table-expand .el-form-item {
        margin-bottom: 0px;
    }
</style>