<template>
    <div class="customer-detail-address">
        <el-button type="danger" size="mini" style="margin-bottom: 20px" @click="openDialog('')">新增</el-button>
        <el-table border :data="addressList" size="mini">
            <el-table-column type="index" label="序号" width="50"></el-table-column>
            <el-table-column label="收件人" width="100" prop="consigneeName"></el-table-column>
            <el-table-column prop="consigneePhone" label="电话" width="110"></el-table-column>
            <el-table-column label="省" prop="provinceName" width="100"></el-table-column>
            <el-table-column prop="cityName" label="市" width="100"></el-table-column>
            <el-table-column prop="areaName" label="区" width="100"></el-table-column>
            <el-table-column prop="address" label="详细地址"></el-table-column>
            <el-table-column label="类型" width="80">
                <template slot-scope="scope">
                    <el-tag type="info">{{addressTypeArr[scope.row.addressType-1].name}}</el-tag>
                </template>
            </el-table-column>
            <el-table-column prop="lastTime" label="使用时间" width="160"></el-table-column>
            <el-table-column label="操作" width="120" fixed="right">
                <template slot-scope="scope">
                    <el-button type="primary" size="mini" icon="el-icon-edit" plain @click="openDialog(scope.row)"></el-button>
                    <el-button type="danger" size="mini" icon="el-icon-delete" plain @click="delAddress(scope.row.addrId)"></el-button>
                </template>
            </el-table-column>
        </el-table>
        <el-dialog
                title="新增/修改地址(都是必填项)"
                :visible.sync="drawerShow"
                :before-close="handleClose">
            <el-form ref="form" :model="formData" label-width="80px" class="draw-box">
                <el-form-item label="收件人" required>
                    <el-input v-model="formData.consigneename"></el-input>
                </el-form-item>
                <el-form-item label="收件手机" required>
                    <el-input v-model="formData.consingeephone" maxlength="11"></el-input>
                </el-form-item>
                <el-form-item label="地址选择" required>
                    <el-cascader
                            placeholder="请选择省市区"
                            v-model="cityValue"
                            :options="addressCityList"
                            :props="{value:'Id',label:'Name',children:'VoList'}"
                            @change="handleCasChange"></el-cascader>
                </el-form-item>
                <el-form-item label="详细地址" required>
                    <el-input v-model="formData.address"></el-input>
                </el-form-item>
                <el-form-item label="类型" required>
                    <el-select v-model="formData.addresstype" placeholder="请选择类型">
                        <el-option
                                v-for="item in addressTypeArr"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" @click="addressSubmit">立即保存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                addressList:[],
                addressCityList:[],
                addressTypeObj:{},
                addressTypeArr:[],
                drawerShow:false,
                cityValue:'',
                formData:{
                    customerId:this.$route.params.id,
                    addrId:'',
                    consingeephone:'',
                    consigneename:'',
                    address:'',
                    addresstype:null,
                    areaid:null
                }
            }
        },
        mounted(){
            this.initList()
        },
        methods:{
            initList() {
                this.$apiGet('lechun-customers/customer/getCustomerAddressInfo',{customerId:this.formData.customerId}).then(res => {
                    console.log(res)
                    this.addressList = res.addressList
                    this.addressTypeArr = res.addressType
                })
            },
            getAddressCityList(){
                this.$apiGet('lechun-customers/customer/getProvinceCityAreaByErp',{transportType:-1,isLongPeriodBuy:0}).then(res=>{
                    console.log(res)
                    let add = res.provinceVoList
                    let newArr = add.map(item=>{
                        item = this.setCityKey(item)
                        item.VoList = item.VoList.map(item1=>{
                            item1 = this.setCityKey(item1)
                            if(item1.VoList != null){
                                item1.VoList = item1.VoList.map(item2=>{
                                    item2 = this.setCityKey(item2)
                                    return item2
                                })
                            }
                            return item1
                        })
                        return item
                    })
                    this.addressCityList = newArr
                })

            },
            openDialog(item){
                this.drawerShow = true;
                this.getAddressCityList();
                if(item){
                    this.formData.addrId = item.addrId
                    this.formData.consingeephone = item.consigneePhone
                    this.formData.consigneename = item.consigneeName
                    this.formData.address = item.address
                    this.formData.addresstype = item.addressType
                    this.formData.areaid = item.areaId
                    this.cityValue = [item.provinceId,item.cityId,item.areaId]
                }
            },
            setCityKey(obj){
                let newObj = {}
                let reg = /Name|Id|VoList/
                for(let key in obj){
                    if(reg.test(key)){
                        newObj[key.match(reg)[0]] = obj[key]
                    }
                }
                return newObj
            },
            handleClose(done) {
                done();
                this.resetForm()
            },
            resetForm(){
                this.formData.addrId = ''
                this.formData.consingeephone = ''
                this.formData.consigneename = ''
                this.formData.address = ''
                this.formData.addresstype = null
                this.formData.areaid = null;
                this.cityValue = ''
            },
            handleCasChange(val){
                this.formData.areaid = val.length>=3?val[2]:'';
            },
            addressSubmit(){
                // if(this.formData.consingeephone.length!=11){
                //     this.$notify({
                //         title: '警告',
                //         message: '请输入正确的手机号',
                //         type: 'warning'
                //     });
                //     return false
                // }
                this.$apiGet('lechun-customers/customer/updateAddress',this.formData).then(res=>{
                    this.drawerShow = false;
                    this.$message({
                        message: '恭喜你，地址保存成功',
                        type: 'success'
                    });
                    this.initList()
                    this.resetForm()
                })
            },
            delAddress(id){
                this.$confirm('此操作将删除该地址, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-customers/customer/deleteAddress',{customerId:this.formData.customerId,addrId:id}).then(res=>{
                        this.$message({
                            type: 'success',
                            message: '清除成功!'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消清除'
                    });
            });

            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .customer-detail-address{
        .header-btn{
            float: right;
        }
        .draw-box{
            padding: 5px;
        }


    }

</style>