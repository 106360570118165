<template>
    <div class="customer-detail-info">
        <el-form size="mini">
            <el-form-item>
                <el-avatar :size="48" :src="customerInfo.headImageUrl"></el-avatar>
            </el-form-item>
        </el-form>
        <el-row :gutter="20">
            <el-col :span="8">
                <el-form :model="customerInfo" class="demo-form-inline" label-width="82px" size="mini">
                    <el-form-item label="昵称">
                        <el-tag type="info">{{customerInfo.nickName}}</el-tag>
                    </el-form-item>
                    <el-form-item label="真实姓名">
                        <el-tag type="info">{{customerInfo.trueName||'无'}}</el-tag>
                    </el-form-item>
                    <el-form-item label="渠道">
                        <el-tag type="info">{{customerInfo.channelId==1?'微信':'其他'}}</el-tag>
                    </el-form-item>
<!--                    <el-form-item label="是否关注">-->
<!--                        <el-tag type="info">{{customerInfo.subscribe==1?'已关注':'未关注'}}</el-tag>-->
<!--                    </el-form-item>-->
                </el-form>
            </el-col>
            <el-col :span="8">
                <el-form :model="customerInfo" class="demo-form-inline" label-width="70px" size="mini">
                    <el-form-item label="性别">
                        <el-tag type="info">{{customerInfo.gender==2?'女':'男'}}</el-tag>
                    </el-form-item>
                    <el-form-item label="* 手机" class="red">
                        <el-input v-model="customerInfo.mobile" placeholder="无" maxlength="11" @change="saveMobile"></el-input>
                    </el-form-item>
                    <el-form-item label="所属地">
                        <el-tag type="info">{{customerInfo.country+customerInfo.province+customerInfo.city||'无'}}</el-tag>
                    </el-form-item>
                </el-form>
            </el-col>
            <el-col :span="8">
                <el-form :model="customerInfo" class="demo-form-inline" label-width="70px" size="mini">
                    <el-form-item label="用户等级">
                        <el-tag type="info">{{customerInfo.levelName}}</el-tag>
                    </el-form-item>
<!--                    <el-form-item label="来源">-->
<!--                        <el-tag type="info">{{customerInfo.customerFrom}}</el-tag>-->
<!--                    </el-form-item>-->
                    <el-form-item label="CUSID">
                        <el-tag type="info">{{customerInfo.loginName}}</el-tag>
                    </el-form-item>
                    <el-form-item label="OPENID">
                        <el-tag type="info">{{customerInfo.channelCustomerId}}</el-tag>
                    </el-form-item>
                </el-form>
            </el-col>
        </el-row>
        <el-form label-width="80px" size="mini">

            <el-form-item label="COOKIE">
                <el-tag type="info">{{customerInfo.sign}}</el-tag>
            </el-form-item>
            <el-form-item label="企微备注">
                <el-tag type="info">{{remark}}</el-tag>
            </el-form-item>
            <el-form-item label="企微标签">
                <el-tag class="tags-success" effect="dark" type="success" v-for="(item,index) in tags">{{item}}</el-tag>
            </el-form-item>
            <el-form-item label="操作">
                <el-button size="mini" type="danger" @click="closeAccount">彻底注销</el-button>
            </el-form-item>
        </el-form>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                customerId:this.$route.params.id,
                tags:[],
                remark:''
            }
        },
        props:['customerInfo'],
        mounted(){
            this.getQwTags();
        },
        methods:{
            returnHistory(){
                window.history.go(-1)
            },
            saveMobile(item){
                // if(item.length!=11){
                //     this.$notify({
                //         title: '警告',
                //         message: '请输入正确的手机号',
                //         type: 'warning'
                //     });
                //     return false
                // }
                this.$apiGet('lechun-customers/customer/saveMobile',{customerId:this.customerId,mobile:item}).then(res=>{
                    this.$notify({
                        title: '成功',
                        message: '手机号保存成功',
                        type: 'success'
                    });
                })
            },

            closeAccount(){
                this.$confirm('注销将不可恢复', '提示', {
                      distinguishCancelAndClose: true,
                      confirmButtonText: '确定',
                }).then((action)=>{
                  this.$apiGet('lechun-cms/customer/closeAccount',{customerId:this.customerId}).then(res=>{
                      this.$notify({
                          title: '成功',
                          message: '注销成功',
                          type: 'success'
                      });
                  })
                })
            },
            getQwTags(){
                this.$apiGet('lechun-customers/customer/getCustomerQwTag',{customerId:this.$route.params.id}).then(res=>{
                    console.log("tags",res);
                    // if(res != null && res.tags != null && res.tags.length > 0){
                        // for (let i = 0; i < res.tags.length; i++) {
                            let tagObj = {}
                            res.tags.map(item=>{
                                if(!tagObj.hasOwnProperty(item.tagName)){
                                    tagObj[item.tagName]= item.tagName
                                }
                            })
                            for(let key in tagObj){
                                this.tags.push(key)
                            }
                        // }
                    // }
                    if(res != null && res.contacts != null && res.contacts.length > 0){
                        for (let i = 0; i < res.contacts.length; i++) {
                            if(res.contacts[i].remark != null){
                                this.remark = this.remark + res.contacts[i].remark + ";";
                            }
                        }
                    }
                })
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .customer-detail-info{
        margin-top: 20px;
        .red .el-form-item__label{
            color: red;
        }
        .el-input{
            width: 100%;
            height: 32px;
        }
        .el-tag{
            width: 100%;
            height: 32px;
        }
        .tags-success{
            width: auto!important;
            margin-right: 10px;
        }
        .el-avatar{
            display: block;
            margin: 0 auto;
        }
    }
</style>