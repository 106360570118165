<template>
    <div>
        <el-tabs v-model="activeName" type="border-card"  @tab-click="initList">
            <el-tab-pane label="活动记录" name="0">
                <fit></fit>
            </el-tab-pane>
            <el-tab-pane label="团购" name="1"  >
                <teamBuy></teamBuy>
            </el-tab-pane>
            <el-tab-pane label="回收活动" name="2"  >
                <activeNoOrder></activeNoOrder>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>

<script>
    import fit from '@/components/active/fit'
    import teamBuy from '@/components/active/teamBuy'
    import activeNoOrder from '@/components/active/activeNoOrder'
    export default {
        data() {
            return {
                activeName:'0',
            }
        },
        components:{
            fit,teamBuy,activeNoOrder
        },
        mounted(){
            this.initList();
        },
        methods: {
            initList() {
                console.log('activeList:'+this.$route.params.id);
            }
        }
    }
</script>


<style scoped>

</style>