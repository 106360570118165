<template>
    <div class="customer-detail-coupon">
        <el-row :gutter="20">
            <el-form :inline="true" :model="formInline" class="demo-form-inline select-wrap">
                <el-form-item label="优惠券名称">
                    <!--<el-input v-model="formInline.ticketBatchId" placeholder="优惠券名称"></el-input>-->
                    <el-select v-model="formInline.ticketBatchId"
                               filterable
                               remote
                               clearable
                               reserve-keyword
                               placeholder="请输入优惠券名称"
                               :remote-method="getCashBatchList"
                               :loading="loading"
                    >
                        <el-option
                                v-for="item in cashList"
                                :key="item.ticketBatchId"
                                :label="item.ticketBatchName"
                                :value="item.ticketBatchId">
                        </el-option>
                    </el-select>
                </el-form-item>

                <el-form-item label="领取时间">
                    <el-date-picker
                            v-model="pickDate"
                            type="daterange"
                            align="right"
                            unlink-panels
                            range-separator="至"
                            start-placeholder="开始日期"
                            end-placeholder="结束日期"
                            format="yyyy 年 MM 月 dd 日"
                            value-format="yyyy-MM-dd"
                            :picker-options="pickerOptions">
                    </el-date-picker>
                </el-form-item>
                <el-form-item label="状态">
                    <el-select v-model="formInline.status" filterable clearable placeholder="状态">
                        <el-option
                                v-for="item in statusList"
                                :key="item.value"
                                :label="item.name"
                                :value="item.value">
                        </el-option>
                    </el-select>
                </el-form-item>
                <el-form-item>
                    <el-button type="primary" size="mini" @click="initList">查询</el-button>
                    <el-button type="danger" size="mini" style="margin-bottom: 20px" @click="openDialog">赠送优惠券</el-button>
                </el-form-item>
            </el-form>
        </el-row>
        <el-row :gutter="20">

            <tablePagination :total="totalRecord" :pageSize="formInline.pageSize" @handlePage="handleCurrentChange">
                <el-table border :data="couponList" size="mini">
                    <el-table-column type="index" label="序号" width="50"></el-table-column>
                    <el-table-column type="expand" label="展开券" width="70">
                        <template slot-scope="scope">
                            <el-row type="flex" class="expand-flex">
                                <el-col class="expand-title">券金额:</el-col>
                                <el-col class="expand-content">
                                    {{scope.row.ticketAmount}}元
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col class="expand-title">起始金额:</el-col>
                                <el-col class="expand-content">
                                    {{scope.row.amountMin}}元
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col class="expand-title">使用订单:</el-col>
                                <el-col class="expand-content">
                                    {{scope.row.orderMainNo}}
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col class="expand-title">使用时间:</el-col>
                                <el-col class="expand-content">
                                    {{scope.row.usedTime}}
                                </el-col>
                            </el-row>
                            <el-row type="flex" class="expand-flex">
                                <el-col class="expand-title">领取时间:</el-col>
                                <el-col class="expand-content">
                                    {{scope.row.createTime}}
                                </el-col>
                            </el-row>

                            <el-row type="flex" class="expand-flex">
                                <el-col class="expand-title">使用规则:</el-col>
                                <el-col class="expand-content">
                                    {{scope.row.usedRule}}
                                </el-col>
                            </el-row>
                            <el-divider></el-divider>
                        </template>
                    </el-table-column>
                    <el-table-column label="券号" width="150" prop="ticketNo"></el-table-column>
                    <el-table-column prop="ticketName" label="券名" width="220"></el-table-column>
                    <el-table-column prop="ncpBat" label="邀请码" width="100"></el-table-column>
                    <el-table-column label="有效期" prop="createTime" width="280">
                        <template slot-scope="scope">
                            {{scope.row.beginTime}} ~ {{scope.row.endTime}}
                        </template>
                    </el-table-column>
                    <el-table-column prop="ruleDesc" label="使用类型"></el-table-column>
                    <el-table-column prop="sourceActiveNo" label="来源"></el-table-column>
                    <el-table-column label="状态" width="80">
                        <template slot-scope="scope">
                            <el-tag :type="couponStatustype[scope.row.status]">{{scope.row.cashStatusName}}</el-tag>
                        </template>
                    </el-table-column>


                    <el-table-column label="操作" width="100" fixed="right">
                        <template slot-scope="scope">
                            <el-button v-if="scope.row.status==1" type="danger" size="mini" @click="handleCloseCoupon(scope.row.ticketId)">禁用</el-button>
                        </template>
                    </el-table-column>

                </el-table>

            </tablePagination>
            <el-dialog title="赠送优惠券" :visible.sync="showDialog">
                <el-form :model="couponBatch"  label-width="120px">
                    <el-form-item label="选择优惠券批次">
                    <el-select v-model="couponBatch.batchId" placeholder="请选择" >
                        <el-option
                                v-for="item in couponBatchList"
                                :key="item.ticketBatchId"
                                :label="item.ticketBatchName"
                                :value="item.ticketBatchId">
                        </el-option>
                    </el-select>
                    </el-form-item>
                    <el-form-item label="数量">
                        <el-input-number v-model="couponBatch.quantity" placeholder="1" :step="1" :min="1" :max="1" readonly="readonly" >
                            <template slot="append">张</template>
                        </el-input-number>
                    </el-form-item>
                    <el-form-item label="">
                        <el-button type="primary" @click="sendCoupon">赠 送</el-button>
                    </el-form-item>
                </el-form>
            </el-dialog>
        </el-row>
    </div>

</template>
<script>
    import tablePagination from '@c/tablePagination'

    export default{
        data(){
            return {
                formInline: {
                    customerId: this.$route.params.id,
                    pageCount: 1,
                    pageSize: 10,
                    start:'',
                    end:'',
                    ticketBatchId:'',
                    status:0
                },
                showDialog:false,
                batchformInline: {
                    pageCount: 1,
                    pageSize: 20,
                    ticketBatchName:''
                },
                couponList:[],
                couponBatch:{quantity:1,batchId:null,customerId:this.$route.params.id},
                couponBatchList:[],
                totalRecord:0,
                cashList:[],
                pickDate:[],
                loading:false,
                pickerOptions: {
                    shortcuts: [{
                        text: '最近一周',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 7);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近一个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 30);
                            picker.$emit('pick', [start, end]);
                        }
                    }, {
                        text: '最近三个月',
                        onClick(picker) {
                            const end = new Date();
                            const start = new Date();
                            start.setTime(start.getTime() - 3600 * 1000 * 24 * 90);
                            picker.$emit('pick', [start, end]);
                        }
                    }]
                },
                statusList: [{value: 0, name: '全部'},{value: 1, name: '未使用'}, {value: 2, name: '已使用'}, {value: 3, name: '已过期'}, {value: 4, name: '部分使用'}, {value: 5, name: '已禁用'}],
                couponStatustype: {
                    '0': '',//未激活
                    '1': 'success',//未使用
                    '2': 'warning',//已使用
                    '3': 'info',//已过期
                    '4': 'warning',//已部分使用
                    '5': 'danger'//已禁用
                },
            }
        },
        components:{
            tablePagination
        },
        mounted(){
            this.initList()
        },

        methods:{
            initList() {
                if(this.pickDate!=''&&this.pickDate!=null){
                    this.formInline.start=this.pickDate[0];
                    this.formInline.end=this.pickDate[1];
                }else {
                    this.formInline.start='';
                    this.formInline.end='';
                }
                this.$apiGet('lechun-customers/cashticket/getCashticketList',this.formInline).then(res=>{
                    console.log(res)
                    this.couponList = res.list
                    this.totalRecord = res.total
                })
            },
            initCouponBatchList(){
                this.$apiGet('lechun-customers/cashticket/getEnableServiceCashticketBatch',{}).then(res=>{
                    console.log(res)
                    this.couponBatchList = res;
                })
            },
            getCashBatchList(queryString){
                this.batchformInline.ticketBatchName=queryString;
                this.loading = true;
                this.$apiGet('lechun-cms/cash/getEnableCashticketList',this.batchformInline).then((res) => {
                    this.loading = false;
                    this.cashList=res.list;
                });
            },
            handleCurrentChange(val) {
                this.formInline.pageCount = val;
                this.initList();
            },
            handleCloseCoupon(id){
                this.$confirm('此操作将禁用该优惠券, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-customers/cashticket/updateCashticketStatusDisabled',{ticketId:id}).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '禁用成功',
                            type: 'success'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });

            },
            sendCoupon(){
                if(this.couponBatch.batchId==null){
                    this.$message({
                        showClose: true,
                        message: '请选择优惠券批次',
                        type: 'error'
                    });
                    return;
                }

                this.$confirm('此操作将为该用户激活'+this.couponBatch.quantity+'张优惠券, 是否继续?', '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    this.$apiGet('lechun-customers/cashticket/sendCashticketByTicketBatchId',this.couponBatch).then(res=>{
                        this.$notify({
                            title: '成功',
                            message: '激活成功',
                            type: 'success'
                        });
                        this.initList()
                    })
                }).catch(() => {
                    this.$message({
                        type: 'info',
                        message: '已取消'
                    });
                });
            },
            openDialog(){
                this.showDialog=true;
                this.initCouponBatchList();
            },
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">
    .customer-detail-coupon{
        .el-table__expanded-cell{
            background: #f5f5f5;
            text-align: left;
            padding: 7px 50px;
        }
        .el-table__expanded-cell:hover{
            background: #f5f5f5 !important;
        }
        .expand-title{
            width: 70px;
            color: #444548;
            line-height: 30px;
            text-align: right;
            margin-right: 10px;
            font-weight: 600;
        }
        .expand-flex,.expand-flex-con{
            justify-content: flex-start;
            align-items: center;
            align-content: center;
        }
        .expand-flex-con{
            display: flex;
            flex-wrap: wrap;
            .expand-content{
                margin: 5px 10px 5px 0;
            }
        }
        .el-table__expand-icon>.el-icon{
            font-size: 16px;
            font-weight: bolder;
        }
    }
</style>