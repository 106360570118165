<template>
    <div class="customer-detail-info">
        <div class="content-wrap">
            <el-table border :data="userBalance" size="mini" class="table-set">
                <el-table-column label="储值信息">
                    <template slot="header" slot-scope="scope">
                        <span>储值信息</span>
                    </template>
                    <template slot-scope="scope">
                        <el-row :gutter="20">
                            <el-col :span="12">
                                <el-form :model="scope.row" class="demo-form-inline" label-width="90px" size="mini">
                                    <el-form-item label="总储值">
                                        <el-input v-model="scope.row.usableTotalBalance" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>
                                    <el-form-item label="赠送储值">
                                        <el-input v-model="scope.row.usableFreeBalance" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>
                                    <el-form-item label="储值状态">
                                        <el-input v-model="scope.row.balanceState == 1 ? '正常' : '停用'" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>

                                </el-form>
                            </el-col>
                            <el-col :span="12">
                                <el-form :model="scope.row" class="demo-form-inline" label-width="90px" size="mini">
                                    <el-form-item label="真实储值">
                                        <el-input v-model="scope.row.usableStoreBalance" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>
                                    <el-form-item label="礼品卡储值">
                                        <el-input v-model="scope.row.usableGifBalance" placeholder="无" :disabled="true"></el-input>
                                    </el-form-item>
                                </el-form>
                            </el-col>
                        </el-row>
                        <el-form label-width="90px" size="mini">
                            <el-form-item label="储值操作">
                                <el-button type="danger" icon="el-icon-minus" @click="reduceManual" plain>减储值</el-button>
                                <el-button type="primary" icon="el-icon-plus" @click="increaseManual" class="header-btn" plain>加储值</el-button>
                            </el-form-item>
                        </el-form>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <div class="content-wrap">
            <h3>余额明细记录</h3>
            <el-table border :data="balanceList" size="mini">
                <el-table-column type="index" label="序号" width="50"></el-table-column>
                <el-table-column label="标题" prop="title" width="120"></el-table-column>
                <el-table-column prop="cash" label="变动金额"></el-table-column>
                <el-table-column prop="wealth" label="变动后总额"></el-table-column>
                <el-table-column prop="usableWealth" label="该笔入账剩余金额"></el-table-column>
                <el-table-column prop="tradeNo" label="主订单号"></el-table-column>
                <el-table-column prop="time" label="时间"></el-table-column>
                <el-table-column label="操作" fixed="right" width="100">
                    <template slot-scope="scope">
                        <el-button v-if="scope.row.title == '储值卡充值' && scope.row.usableWealth > 0" type="primary" size="mini" @click="handleRefundCharge(scope.row)">退余额</el-button>
                    </template>
                </el-table-column>
            </el-table>
        </div>
        <el-dialog :title="balanceMessage" :visible.sync="showDialog">
            <el-form :model="balanceOperate" :rules="rules" label-width="120px">
                <el-form-item label="输入金额">
                    <el-input-number v-model="balanceOperate.amount" placeholder="0 " :step="0.01" :min="0.01" :max="1000">
                        <template slot="append">元</template>
                    </el-input-number>
                </el-form-item>
                <el-form-item label="描述(必填)">
                    <el-input v-model="balanceOperate.detailSubTypeDesc" placeholder="请输入描述"></el-input>
                </el-form-item>
                <el-form-item label="操作">
                    <el-button type="primary" @click="saveBalance">保 存</el-button>
                </el-form-item>
            </el-form>
        </el-dialog>
    </div>
</template>
<script>
    export default{
        data(){
            return {
                userBalance: [{}],
                balanceOperate: {amount: 0, detailSubTypeDesc: ''},
                balanceList: [],
                balanceOperateType: 1,
                balanceOperateMessage: '',
                balanceMessage: '',
                showDialog: false,
                customerId: this.$route.params.id,
                rules: {
                    amount: [
                        {required: true, message: '请输入金额', trigger: 'blur'}
                    ],
                    detailSubTypeDesc: [
                        {required: true, message: '请输入描述', trigger: 'blur'}
                    ]
                }
            }
        },
        mounted(){
            this.initList()
        },
        methods:{
            initList() {
                this.$apiGet('lechun-balance/api/getUserBalanceDetail',{customerId:this.customerId}).then(res => {
                    // console.log('getUserBalanceDetail',res);
                    this.balanceList = res.records;
                    // const myDate = new Date();
                    // for(let b in  this.balanceList){
                    //     b.disableTime = new Date();
                    //     b.time.setYear(b.time.getFullYear() + 1)
                    // }
                    this.userBalance = [res.userBalance];
                })
            },
            handleRefundCharge(row){
                this.$confirm('该笔储值还剩余' + row.usableWealth + "，退款将会扣除赠送部分，确定退款吗？", '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    //alert(1);
                    this.$apiGet('lechun-csms/refundApi/refundBalance', {"customerId":this.customerId,"orderMainNo":row.tradeNo}).then(res => {
                        if(res.data.status==200){
                            this.$message({
                                showClose: true,
                                message: '成功',
                                type: 'success'
                            });
                            this.getUserBalanceDetail();
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'error'
                            });
                        }
                    })
                    /*refundCharge({"customerId":this.$route.params.id,"orderMainNo":row.tradeNo}).then((res) => {
                        if(res.data.status==200){
                            this.$message({
                                showClose: true,
                                message: '成功',
                                type: 'success'
                            });
                            this.getUserBalanceDetail();
                        }else{
                            this.$message({
                                showClose: true,
                                message: res.data.message,
                                type: 'error'
                            });
                        }
                    })*/
                }).catch(() => {

                });
            },
            increaseManual(){
                this.showDialog = true;
                this.balanceOperateType = 1;
                this.showDialog = true;
                this.detailSubTypeDesc = '';
                this.balanceOperateMessage = '确定增加余额吗?';
                this.balanceMessage = '增加储值';
            },
            reduceManual(){
                this.showDialog = true;
                this.balanceOperateType = 2;
                this.showDialog = true;
                this.detailSubTypeDesc = '';
                this.balanceOperateMessage = '确定减少余额吗?';
                this.balanceMessage = '减少储值';
            },
            /*getCookie(name){
                var arr, reg = new RegExp("(^| )" + name + "=([^;]*)(;|$)");
                if (arr = document.cookie.match(reg))
                    return unescape(arr[2]);
                else
                    return null;
            },*/
            saveBalance(){
                if(this.balanceOperate.detailSubTypeDesc==''){
                    this.$message({
                        showClose: true,
                        message: '描述必填',
                        type: 'error'
                    });
                    return;
                }
                // var operator = this.getCookie("userName");
                // if(operator == null){
                //     this.$message({
                //         showClose: true,
                //         message: '失败，请重新登录',
                //         type: 'error'
                //     });
                // }
                this.$confirm(this.balanceOperateMessage, '提示', {
                    confirmButtonText: '确定',
                    cancelButtonText: '取消',
                    type: 'warning'
                }).then(() => {
                    let apiUrl = this.balanceOperateType == 1?'lechun-balance/api/increaseManual':'lechun-balance/api/reduceManual'

                    let params;
                    if(this.balanceOperateType == 1){
                        params= {
                            'customerId':this.customerId,
                            'increaseAmount': this.balanceOperate.amount,
                            'detailSubTypeDesc': this.balanceOperate.detailSubTypeDesc,
                            'operator': ''
                        }
                    }else{
                        params= {
                            'customerId':this.customerId,
                            'reduceAmount': this.balanceOperate.amount,
                            'detailSubTypeDesc': this.balanceOperate.detailSubTypeDesc,
                            'operator': ''
                        }
                    }

                    this.$apiGet(apiUrl,params).then(res => {
                        this.$notify({
                            title: '成功',
                            message: '操作成功',
                            type: 'success'
                        });
                        this.showDialog=false;
                        this.initList();
                    })
                })
            }
        }
    }
</script>

<style lang="scss" rel="stylesheet/scss">

    .customer-detai-lbalance{
        .el-input.is-disabled .el-input__inner {
            color: #8c8c8c;
        }
        .red .el-form-item__label {
            color: red;
        }
        .el-select {
            width: 100%;
        }
        .table-set.el-table td {
            text-align: left;
        }
    }
</style>
